import * as React from "react"

import { Head } from "@/components/Head"
import { GlobalLayout } from "@/components/GlobalLayout"
import { NewsSection } from "@/components/sections/NewsSection"
import { PageNewsArchiveQuery } from "@root/types/graphql-types"
import { Pagination } from "@/components/Pagination"
import { PaginationPageContext } from "@/types/PageContext"
import { PageProps } from "gatsby"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"
import { useImage } from "@/hooks/useImage"

type Props = PageProps<PageNewsArchiveQuery, PaginationPageContext>

export const News: React.FC<Props> = ({ data, pageContext }) => {
    const { getImageProps, Image } = useImage()
    return (
        <GlobalLayout
            Head={<Head title="news archive" description="news Archive" />}
        >
            <NewsSection
                newsArticles={data.allContentfulNews.edges.map((e) => e.node)}
                Title={
                    <TitleWithBgImg
                        title="NEWS ARCHIVE"
                        BgImage={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData
                                )}
                            />
                        }
                    />
                }
            >
                <Pagination
                    currentPage={pageContext.currentPage}
                    numPages={pageContext.numberOfPages}
                    basePath="/news"
                    className="p-section__pagination"
                />
            </NewsSection>
        </GlobalLayout>
    )
}
