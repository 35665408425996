import { graphql } from 'gatsby'
import { News } from '@/components/pages/News'

export default News

export const query = graphql`
    query PageNewsArchive($skip: Int!, $limit: Int!) {
        contentfulSite {
            siteHeroImage {
                gatsbyImageData(resizingBehavior: FILL)
            }
        }
        allContentfulNews(
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: updatedAt }
            filter: { slug: { ne: "dummy" } }
        ) {
            edges {
                node {
                    ...NewsInformation
                }
            }
        }
    }
`
